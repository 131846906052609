const config = {
  COOKIES_DOMAIN: ".koble.mx",
  NEXT_PUBLIC_IDENTITY_SERVER: "https://identity.koble.mx",
  NEXT_PUBLIC_GRAPHQL: "https://graphql.koble.mx/graphql",
  NEXT_PUBLIC_API: "https://api.koble.mx",
  NEXT_PUBLIC_WS: "wss://ws.koble.mx",
  NEXT_PUBLIC_SSO_FRONTEND: "https://sso.koble.mx",
  NEXT_PUBLIC_USER_STUDENT_FRONTEND: "https://student.koble.mx",
  NEXT_PUBLIC_USER_RECRUITER_FRONTEND: "https://recruiter.koble.mx",
  NEXT_PUBLIC_BACK_OFFICE_FRONTEND: "https://backoffice.koble.mx",
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: "6LebnXApAAAAAI38PWdC4s6eQB9X8w6LyLdIV1ZH",
  NEXT_PUBLIC_GOOGLE_CLIENT_ID:
    "781551067901-9kgod7hs6nmahmh7u04g85hldlgso01a.apps.googleusercontent.com",
  MIX_PANEL_TOKEN: "65e15c06dcbef42cb3e70a05e1c09562",
  FACEBOOK_PIXEL_CODE: 7544537748965734,
};

export default config;
